<template>
  <div class="pageContol listWrap templateList" id="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
      </div>
      <div class="framePage-body" :class="$judgeServerHostname()==1?'innerMongolia-bgm':'common-bgm'">
        <div class="framePage-scroll">
          <div class="ovy-a newIndex">
            <div style="height:100%; display: flex; width:100%;">
              <el-card class="box-card">
                <span class="tittleIndex">系统公告</span>
<!--                <el-button-->
<!--                    @click="moreTest1"-->
<!--                    type="text"-->
<!--                    class="theText"-->
<!--                >-->
<!--                  个人认证-->
<!--                </el-button>-->
<!--                <el-button-->
<!--                    @click="moreTest2"-->
<!--                    type="text"-->
<!--                    class="theText"-->
<!--                >-->
<!--                  企业认证-->
<!--                </el-button>-->
                <el-button
                  style="float: right; padding: 3px 0"
                  @click="more"
                  type="text"
                  class="theText"
                >
                  更多
                  <i class="el-icon-arrow-right"></i>
                </el-button>
                <div class="announcement">
                  <el-table
                    :data="tableData"
                    stripe
                    :show-header="false"
                    highlight-current-row
                    @row-click="handleCurrentChange"
                    style="width:100%;height:100%;font-size:12px"
                    height="480px"
                  >
                    <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      :index="indexMethod"
                      width="40"
                    />
                    <el-table-column prop="noticeTitle" show-overflow-tooltip label="公告"></el-table-column>
                    <el-table-column prop="createTime" align="right" label="日期" width="100">
                      <template slot-scope="scope">{{scope.row.createTime|momentDate}}</template>
                    </el-table-column>
                    <Empty slot="empty" />
                  </el-table>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import LoginRecommend from "@/components/loginRecommend.vue";
import {mapGetters} from "vuex";
let echart1 = {};
export default {
  name: "operate/home",
  components: {
    Empty,
    LoginRecommend
  },
  data() {
    return {
      hideEmpty: true,
      tableData: [],
      currentRo: "",
      oldCurrentRow: ""
    };
  },
  created() {
    this.getnew();
  },
  mounted() {},
  computed: {},
  methods: {
    changePassword() {
      if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
        this.$router.push("/web/operate/change");
      }
    },
    more() {
      this.$router.push("/web/systemNotication");
    },

    moreTest1() {
      this.$router.push("/azhr/humanResources/personalCertification");
    },
    moreTest2() {
      this.$router.push("/azhr/humanResources/enterpriseCertification");
    },
    getnew() {
      const parmas = {
        pageNum: "1",
        pageSize: "10"
      };
      this.$post("/notice/querySysNoticeList", parmas).then(res => {
        this.tableData = res.data.list;
      });
    },
    //点击公告 进入对应的 公告详情页面
    handleCurrentChange(val) {
      const noticeId = val.noticeId;
      this.$router.push({
        path: "/web/noticationDetail",
        query: {
          noticeId
        }
      });
    }
  }
};
</script>
<style lang="less">
</style>
<style lang="less" scope>
.common-bgm{
  background-size: cover!important;
    background: url("../../assets/newIndex.png") no-repeat;
}
.innerMongolia-bgm{
    background: url("../../assets/loginInnerMongolia/newIndex.png") no-repeat;
}
#pageContol {
  table {
    width: 100% !important;
  }
  .framePage-body {
    // background: url("../../assets/newIndex.png") no-repeat;
    background-size: 100% 100%;
  }
  .newIndex {
    width: 100%;
    height: 100%;
    // background: url("../../assets/newIndex.png") no-repeat;
    background-size: 100%;
    position: relative;
    .box-card {
      width: 30%;
      min-width: 450px;
      background: rgba(92, 107, 232, 1);
      box-shadow: 0px 5px 24px 0px rgba(92, 92, 92, 0.35);
      border-radius: 10px;
      position: absolute;
      right: 8%;
      .el-card__body {
        height: 100%;
        .announcement {
          margin: 0 auto;
          margin-top: 20px;
          background: rgba(255, 255, 255, 1);
          width: 100%;
          height: 100%;
          border-radius: 4px;

          .el-table {
            border-radius: 10px;
            height: 100%;
            .el-table__body-wrapper {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

.tittleIndex {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.theText {
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.incontent {
  width: 400px;
}
</style>
<style lang="less">
// #pageContol {
// .announcement {
//   .el-table.ovy-a.el-table--fit.el-table--striped {
//     .el-table__body-wrapper.is-scrolling-left {
//       height: 100% !important;
//     }
//   }
// }
// }
</style>
